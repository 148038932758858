.obx-audio-player-container {
  align-self: center;
  display: contents;
  max-width: 500px;

  .obx-audio-player-art-container {
    width: 100%;
    display: flex;
    justify-content: center;

    .obx-audio-player-art {
      max-width: 300px;
    }
  }
}

.react-jinke-music-player-mobile-cover {
  border-radius: initial !important;
}

.react-jinke-music-player-mobile-cover .cover {
  animation: none !important;
}

.react-jinke-music-player-mobile-header-right {
  left: 0;
  width: 10%;
}

.audio-lists-panel-header-delete-btn,
.audio-item .player-singer,
.audio-item .player-delete {
  display: none !important;
}

.audio-lists-panel-header-title span:nth-child(-n + 2) {
  display: none !important;
}

.react-jinke-music-player-mobile-header-title {
  font-size: 16px;
  text-overflow: initial;
  white-space: initial;
}
